// import {store1} from '../store/index's

var DataStore = require('../store');
var store = DataStore.default

const fetchPOST = () => {
  return store.state.kamio
}


const getUnitKerja = async (instansi) =>{
  return new Promise(resolve=>{
    fetch(store.state.url.URL_simpeg_instansi + "list", {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
        },
        body: JSON.stringify({
          cari: instansi
        })
      })
        .then(res => res.json())
        .then(res_data => {
          console.log(res_data)
          // store.state.list_instansi = res_data
          // store.state.list_instansi
          resolve(res_data)

    });
  })
}

const getUnitKerjaAutocomplete = async (instansinya, unit_kerja)=>{
  return new Promise(resolve=>{
    fetch(store.state.url.URL_simpeg_unit_kerja + "getUnitKerjaAutocomplete", {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
        },
        body: JSON.stringify({
          instansi_id: instansinya,
          unit_kerja: unit_kerja
        })
      })
        .then(res => res.json())
        .then(res_data => {
          // console.log(res_data)
          resolve(res_data)

    });
  })
}

const getMasterPajak = async ()=>{

  fetch(store.state.url.URL_masterAdm + "list_pajak", {
      method: "GET",
      headers: {
      "content-type": "application/json",
      authorization: "kikensbatara " + localStorage.token
      }
    })
      .then(res => res.json())
      .then(res_data => {
        // console.log(res_data);
        store.state.list_masterPajak = res_data
  });

}


module.exports = {
  fetchPOST: fetchPOST,
  getUnitKerja : getUnitKerja,
  getUnitKerjaAutocomplete : getUnitKerjaAutocomplete,
  getMasterPajak : getMasterPajak,

}