

import { ModelSelect, ModelListSelect  } from 'vue-search-select'
import 'vue-search-select/dist/VueSearchSelect.css'


import DATA_MASTER from  '../../library/dataMaster.js'
import FETCHING from '../../library/fetching.js'
import UMUM from "../../library/umum.js";
  

export default {
   components: {
      ModelSelect, ModelListSelect, 
  },
  data() {
    return {
        
        
      form : {
          id : '',
          uraian: '',
        },


      list_data : [],
      
      cek_load_data : true,


       mdl_add : false,
        mdl_edit : false,
        mdl_hapus : false,
   

        page_first: 1,
        page_last: 0,
        page_limit : 10,
        cari_value: "",
        FETCHING : FETCHING,
        UMUM : UMUM,
        
      DATA_MASTER : DATA_MASTER, 
    }
  },
  methods: {

  

    
    getView : function(){
      this.$store.commit("shoWLoading");
      fetch(this.$store.state.url.URL_masterJenis + "view", {
            method: "POST",
            headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                data_ke: this.page_first,
                cari_value: this.cari_value,
                page_limit : this.page_limit,
                
            })
        })
          .then(res => res.json())
          .then(res_data => {
              this.list_data = res_data.data;
              this.page_last = res_data.jml_data;
              this.$store.commit("hideLoading");
              console.log(res_data);
      });
    },
    
    addData : function() {
        
        fetch(this.$store.state.url.URL_masterJenispengeluaran + "addData", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
            form : this.form,
          })
            
        }).then(res_data => {
            this.getView();
           this.Notify ('Sukses Menambah Data', 'primary', 'check_circle_outline');
        });


      },


    editData : function(){
      fetch(this.$store.state.url.URL_masterJenispengeluaran + "editData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
            form : this.form
          })
      }).then(res_data => {
          this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
          this.getView();
      });
    },

  
    removeData : function(E){
      fetch(this.$store.state.url.URL_masterJenispengeluaran + "removeData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({id : this.form.id})
      }).then(res_data => {
          this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
          this.getView();
      });

    },

   


   selectData : function(data){

          this.form.id= data.id;
          this.form.jenis_pengeluaran = data.jenis_pengeluaran;
 

      },
    


    // ====================================== CONTOH eDOC ====================================
    onClickx(data){
      alert(data)
    },
    // ====================================== CONTOH eDOC ====================================






    // ====================================== PAGINATE ====================================
        Notify : function(message, positive, icon){
          this.$q.notify({
            message: message,
            color: positive,
            icon: icon,
            position : 'top',
            timeout: 500,
          })
        },
        btn_prev : function(){
            this.cek_load_data = true;
            if(this.page_first>1){
                this.page_first--
            }else{
                this.page_first = 1;
            }
            this.getView();
        },

        btn_next : function(){
            if(this.page_first >= this.page_last){
                this.page_first == this.page_last
            }else{
                this.page_first++;
            }
            this.getView();
        },

        cari_data : function(){
            this.page_first = 1;
            this.getView();
        },

        indexing : function(index){
            var idx = ((this.page_first-1)*this.page_limit)+index
            return idx
        },

    // ====================================== PAGINATE ====================================







  },

  mounted () {
    
      this.getView();

  },
}
